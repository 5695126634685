<template>
    <div>
        <!-- 还需要一个系统配置的功能 配置全局的 logo 名称  图片 系统集合配置： 默认首页 页面标题 标题点击跳转 页面logo 页面logo跳转 隐藏菜单 --->
        <el-dialog title="我是标题" :visible.sync="drawer" :before-close="handleClose" :max-height="maxHeight">
            <div class="form-container">
                <div class="form-item-container">
                    <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="100px" class="demo-ruleForm">
                        <el-row>
                            <el-form-item label="种类" prop="name">
                                <el-radio-group v-model="ruleForm.type">
                                    <el-radio :label="1">茶</el-radio>
                                    <el-radio :label="2">菜</el-radio>
                                    <el-radio :label="3">调酒</el-radio>
                                    <el-radio :label="4">甜点</el-radio>
                                </el-radio-group>
                            </el-form-item>
                        </el-row>
                        <el-row>
                            <el-col :span="12">
                                <el-form-item label="类别" prop="name">
                                    <el-select v-model="ruleForm.productSource" placeholder="请选择">
                                        <el-option v-for="item in dataList" :key="item.code" :label="item.name"
                                            :value="item.code"></el-option>
                                    </el-select>
                                </el-form-item>
                            </el-col>
                            <el-col :span="12">
                                <el-form-item label="餐别" prop="name">
                                    <el-input v-model="ruleForm.name"></el-input>
                                </el-form-item>
                            </el-col>

                        </el-row>
                        <el-row>
                            <el-col :span="12">
                                <el-form-item label="名字" prop="name">
                                    <el-input v-model="ruleForm.name"></el-input>
                                </el-form-item>
                            </el-col>
                            <el-col :span="10">
                                <el-form-item label="是否尝试" prop="name">
                                    <el-input v-model="ruleForm.name"></el-input>
                                </el-form-item>
                            </el-col>
                        </el-row>
                        <el-row>
                            <el-form-item label="图片" prop="name">
                                <el-upload class="upload-demo" :action="uploadUrl" :on-success="handleSuccess"
                                    :list-type="listType" :on-exceed="handleExceed" :auto-upload="false"
                                    :file-list="fileList" :limit="3">
                                    <el-button slot="trigger" size="small" type="primary">选取文件</el-button>
                                    <div slot="tip" class="el-upload__tip">只能上传3个文件，每个文件不超过500kb，且只能上传jpg/png文件</div>
                                </el-upload>
                            </el-form-item>
                        </el-row>
                        <el-row>
                            <el-form-item label="实物图" prop="name">
                                <el-upload class="upload-demo" :action="uploadUrl" :on-success="handleSuccess"
                                    :list-type="listType" :on-exceed="handleExceed" :auto-upload="false"
                                    :file-list="fileList" :limit="3">
                                    <el-button slot="trigger" size="small" type="primary">选取文件</el-button>
                                    <div slot="tip" class="el-upload__tip">只能上传3个文件，每个文件不超过500kb，且只能上传jpg/png文件</div>
                                </el-upload>
                            </el-form-item>
                        </el-row>
                        <el-row>
                            <el-col :span="10">
                                <el-form-item label="来源">
                                    <el-input v-model="ruleForm.username"></el-input>
                                </el-form-item>
                            </el-col>
                            <el-col :span="14">
                                <el-form-item label="标签">
                                    <el-input v-model="ruleForm.password" />
                                </el-form-item>
                            </el-col>
                        </el-row>
                        <el-row>
                            <span>配置</span>
                            <el-tabs v-model="activeName" type="border-card" @tab-click="handleClick">
                                <el-tab-pane label="基本信息" name="first">

                                    <el-row>
                                        <el-col :span="12">
                                            <el-form-item label="难度" prop="status">
                                                <el-select v-model="ruleForm.productSource" placeholder="请选择">
                                                    <el-option v-for="item in dataList" :key="item.code" :label="item.name"
                                                        :value="item.code"></el-option>
                                                </el-select>
                                            </el-form-item>
                                        </el-col>
                                        <el-col :span="12">
                                            <el-form-item label="推荐级别" prop="status">
                                                <el-select v-model="ruleForm.productSource" placeholder="请选择">
                                                    <el-option v-for="item in dataList" :key="item.code" :label="item.name"
                                                        :value="item.code"></el-option>
                                                </el-select>
                                            </el-form-item>
                                        </el-col>
                                    </el-row>
                                    <el-row>
                                        <el-col :span="12">
                                            <el-form-item label="冲泡次数" prop="name" label-position="left">
                                                <el-input-number v-model="ruleForm.name" style="width: 80%;" />
                                            </el-form-item>
                                        </el-col>
                                        <el-col :span="12">
                                            <el-form-item label="预算" prop="name" label-position="left">
                                                <el-input-number v-model="ruleForm.name" style="width: 80%;" />
                                            </el-form-item>
                                        </el-col>
                                    </el-row>

                                    <el-row>
                                        <el-form-item label="器具" prop="name" label-position="left">
                                            <el-input v-model="ruleForm.name" style="width: 80%;" />
                                        </el-form-item>
                                    </el-row>
                                    <el-row>
                                        <el-form-item label="温度" prop="name" label-position="left">
                                            <el-input v-model="ruleForm.name" style="width: 80%;" />
                                        </el-form-item>
                                    </el-row>


                                    <el-row>
                                        <el-form-item label="口味" prop="name" label-position="left">
                                            <el-input v-model="ruleForm.name" style="width: 80%;" />
                                        </el-form-item>
                                    </el-row>
                                    <el-row>
                                        <el-form-item label="小贴士" prop="name" label-position="left">
                                            <el-input v-model="ruleForm.name" style="width: 80%;" />
                                        </el-form-item>
                                    </el-row>
                                    <el-row>
                                        <el-form-item label="适合人群" prop="name" label-position="left">
                                            <el-input v-model="ruleForm.name" style="width: 80%;" />
                                        </el-form-item>
                                    </el-row>
                                    <el-row>
                                        <el-form-item label="备注" prop="name" label-position="left">
                                            <el-input v-model="ruleForm.name" style="width: 80%;" />
                                        </el-form-item>
                                    </el-row>

                                </el-tab-pane>
                                <el-tab-pane label="功效信息" name="second">
                                    <el-row>
                                        <el-form-item label="滋补养生功效" prop="status">
                                            <!-- 滋补养生的重点是滋补身体，弥补营养不足，增强体质，促进健康。 -->
                                            <el-input placeholder="比如:补气,补血,益肾,健脾,养肝,润肺,安神,抗衰老" />
                                        </el-form-item>
                                    </el-row>
                                    <el-row>
                                        <el-form-item label="食疗养生功效" prop="status">
                                            <!-- 食疗养生的重点是治疗疾病，改善身体不适，预防疾病。 -->
                                            <el-input placeholder="比如:补虚益气,滋阴润燥,养胃健脾,降脂减肥,缓解压力等" />
                                        </el-form-item>
                                    </el-row>
                                    <el-row>
                                        <el-form-item label="调节身体功效" prop="status">
                                            <el-input placeholder="比如:提高身体免疫力、缓解疲劳、改善睡眠、减轻压力和焦虑、促进消化、预防疾病等" />
                                        </el-form-item>
                                    </el-row>
                                    <el-row>
                                        <el-form-item label="美容养颜功效" prop="status">
                                            <el-input placeholder="比如:保持皮肤弹性和光泽,减少皱纹和色斑,促进血液循环,延缓衰老等" />
                                        </el-form-item>
                                    </el-row>
                                    <el-row>
                                        <el-form-item label="保健健身功效" prop="status">
                                            <el-input placeholder="比如:提高身体素质、协调性和运动能力;增强体力、耐力;降低体脂肪含量，塑造健康美体等" />
                                        </el-form-item>
                                    </el-row>

                                </el-tab-pane>
                                <el-tab-pane label="产品信息" name="third">
                                    <el-row>
                                        <el-form-item label="产品名称" prop="status">
                                            <el-input placeholder="比如: 酒水" />
                                        </el-form-item>
                                    </el-row>
                                    <el-row>
                                        <el-form-item label="产品规格" prop="status">
                                            <el-input placeholder="比如: 500ml" />
                                        </el-form-item>
                                    </el-row>
                                </el-tab-pane>
                                <el-tab-pane label="操作步骤" name="three">
                                    <el-row>
                                        <el-form-item label="材料准备" prop="name" label-position="left">
                                            <el-table :data="tableData" :header-row-height="50" style="margin: -10px 0px;">
                                                <el-table-column fixed prop="date" label="序号">
                                                </el-table-column>
                                                <el-table-column fixed prop="date" label="材料名字">
                                                </el-table-column>
                                                <el-table-column prop="name" label="费用">
                                                </el-table-column>
                                                <el-table-column prop="province" label="备注">
                                                </el-table-column>
                                                <el-table-column prop="province" label="数量">
                                                </el-table-column>
                                                <el-table-column prop="province" label="单位">
                                                </el-table-column>
                                                <el-table-column prop="city" label="购买链接">
                                                </el-table-column>
                                            </el-table>
                                            <div style="margin: 4px 40%;">
                                                <el-button type="text" @click="addRow" size="mini"
                                                    style="opacity: 0.7;">添加</el-button>
                                            </div>
                                        </el-form-item>
                                    </el-row>
                                    <el-row>
                                        <el-form-item label="操作步骤" prop="name" label-position="left">
                                            <el-table :data="tableDatas" :header-row-height="50" style="margin: -10px 0px;">
                                                <el-table-column fixed prop="date" label="序号">
                                                </el-table-column>
                                                <el-table-column fixed prop="date" label="类型">
                                                </el-table-column>
                                                <el-table-column prop="name" label="时间">
                                                </el-table-column>
                                                <el-table-column prop="province" label="描述">
                                                </el-table-column>
                                                <el-table-column prop="city" label="是否收藏">
                                                </el-table-column>
                                                <el-table-column prop="city" label="操作">
                                                </el-table-column>
                                            </el-table>
                                            <div style="margin: 4px 40%;">
                                                <el-button type="text" @click="addRow" size="mini"
                                                    style="opacity: 0.7;">添加</el-button>
                                            </div>
                                        </el-form-item>
                                    </el-row>
                                </el-tab-pane>
                                <el-tab-pane label="成分信息" name="threes">
                                    <div class="ingredient-div">
                                        <el-row class="ingredient-row" v-for="(item, index) in items" :key="index">
                                            <el-col :span="10">
                                                <span v-if="item.name != ''">{{ item.name }}</span>
                                                <el-select v-model="ruleForm.productSource" placeholder="请选择" v-else>
                                                    <el-option v-for="item in dataList" :key="item.code" :label="item.name"
                                                        :value="item.code"></el-option>
                                                </el-select>
                                            </el-col>
                                            <el-col :span="14">
                                                <el-input-number v-model="ruleForm.name" size="medium"
                                                    controls-position="right" style="width: 100%;" />
                                            </el-col>
                                        </el-row>
                                    </div>
                                    <div style="margin: 4px 10%;">
                                        <el-button @click="addInfo">添加</el-button>
                                        <el-button @click="addInfo">自动分析</el-button>
                                        <el-button @click="analyzeShowButton">查看分析</el-button>
                                    </div>
                                </el-tab-pane>
                                <el-tab-pane label="分享内容" name="fourth">
                                    <el-row>
                                        <el-form-item label="分享链接" prop="name" label-position="left">
                                            <el-input v-model="ruleForm.name" style="width: 80%;" />
                                        </el-form-item>
                                    </el-row>
                                    <el-row>
                                        <el-form-item label="标题" prop="name" label-position="left">
                                            <el-input v-model="ruleForm.name" style="width: 80%;" />
                                        </el-form-item>
                                    </el-row>
                                    <el-row>
                                        <el-form-item label="内容" prop="name" label-position="left">
                                            <el-input v-model="ruleForm.name" style="width: 80%;" />
                                        </el-form-item>
                                    </el-row>
                                    <el-row>
                                        <el-form-item label="分享文案" prop="name" label-position="left">
                                            <el-input v-model="ruleForm.name" style="width: 80%;" />
                                        </el-form-item>
                                    </el-row>
                                    <el-row>
                                        <el-form-item label="是否发实物图" prop="name" label-position="left">
                                            <el-input v-model="ruleForm.name" style="width: 80%;" />
                                        </el-form-item>
                                    </el-row>
                                    <el-row>
                                        <el-button style="margin: 0px 40%;" @click="previewButton">预览</el-button>
                                    </el-row>
                                </el-tab-pane>
                            </el-tabs>
                        </el-row>
                    </el-form>
                    <div class="demo-drawer__footer">
                        <el-button type="primary" @click="submitForm('ruleForm')">立即创建</el-button>
                        <el-button @click="resetForm('ruleForm')">重置</el-button>
                    </div>
                </div>
                <div class="img-container" v-if="(this.previewShow) & (this.activeName == 'fourth')"
                    style="border: 1px solid red; width: 60%;height:100%;">
                    <img :src="url" alt="头像">
                </div>
                <div class="img-container" v-if="(this.analyzeShow) & (this.activeName == 'threes')"
                    style="border: 1px solid red; width: 60%;height:100%;">
                    <img :src="url" alt="头像">
                </div>
            </div>
        </el-dialog>
    </div>
</template>
<script>
export default {
    data() {
        return {
            previewShow: false,
            analyzeShow: false,
            fits: ["fill", "contain", "cover"],
            url: "https://fuss10.elemecdn.com/e/5d/4a731a90594a4af544c0c25941171jpeg.jpeg",
            dataList: [],
            drawer: false,
            activeName: "first",
            ruleForm: {},
            rules: {},
            maxHeight: 400, // 设置max-height为窗口高度的一部分
            formLabelWidth: 80,
            tableData: [],
            tableDatas: [],
            infoList: [],
            dialogImageUrl: "",
            dialogVisible: false,
            uploadUrl: "your-upload-url",
            fileList: [],
            listType: "picture-card", // 'text' or 'picture-card'
            items: [
                {
                    type: "1",
                    name: "热量",
                },
                {
                    type: "1",
                    name: "蛋白质",
                },
                {
                    type: "1",
                    name: "碳水化合物",
                },
                {
                    type: "1",
                    name: "维生素",
                },
                {
                    type: "1",
                    name: "脂肪",
                },
                {
                    type: "1",
                    name: "纤维素",
                },

                {
                    type: "1",
                    name: "水",
                },
                {
                    type: "1",
                    name: "矿物质",
                },
            ],
        };
    },
    mounted() {
        this.maxHeight = window.innerHeight * 0.8; // 设置max-height为窗口高度的80%
    },
    methods: {
        handleClick(tab, event) {
            if (this.previewShow) {
                this.previewShow = !this.previewShow;
            }
            if (this.analyzeShow) {
                this.analyzeShow = !this.analyzeShow;
            }

            console.log(tab, event);
        },
        show(row, type) {
            console.log('11')
            this.drawer = true;
        },
        handleClose(done) {
            this.$confirm("确认关闭？")
                .then((_) => {
                    done();
                })
                .catch((_) => { });
        },
        submitForm(formName) {
            this.$refs[formName].validate((valid) => {
                if (valid) {
                    alert("submit!");
                } else {
                    console.log("error submit!!");
                    return false;
                }
            });
        },
        resetForm(formName) {
            this.$refs[formName].resetFields();
        },
        handleSuccess(response, file, fileList) {
            console.log(response, file, fileList);
        },

        addRow() { },
        handleExceed(files, fileList) {
            this.$message.warning(
                `当前限制选择 3 个文件，本次选择了 ${files.length} 个文件，共选择了 ${files.length + fileList.length
                } 个文件`
            );
        },
        addInfo() {
            var data = {
                name: "",
            };
            this.items.push(data);
        },
        previewButton() {
            this.previewShow = !this.previewShow;
        },
        analyzeShowButton() {
            this.analyzeShow = !this.analyzeShow;
        },
    },
};
</script>
<style  lang="less" scoped>
.demo-ruleForm {
    overflow-y: auto;
    max-height: 90%; // 设置最大高度
}

.demo-drawer__footer {
    display: flex;
    justify-content: flex-end;
    padding: 0px 10px;
    border-top: 1px solid #ebeef5;
}

.ingredient-div {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    margin-bottom: 20px;
}

.ingredient-row {
    width: 50%;
    margin-bottom: 20px;
    text-align: center;
    padding: 0px 20px;
    box-sizing: border-box;
}

.form-container {
    display: flex;
    align-items: center;
}

.img-container {
    margin-left: 6px;
    order: 2;
    align-self: stretch;
    display: flex;
    justify-content: flex-end;
}

.form-item-container {
    flex: 1;
    order: 1;
}

.img-container img {
    width: 80%;
    object-fit: contain;
}
</style>
